<template>
  <div class="row q-pt-lg q-px-lg">
    <q-dialog
      v-model="dialog.show"
      persistent
    >
      <q-card style="width: 300px">
        <q-card-section>
          <div>{{ dialog.message }}</div>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn flat label="Close" v-close-popup @click="onModalClose" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <div class="col-xs-12 col-sm-12 col-md-4 q-mr-lg q-mb-lg">
      <q-card>
        <q-card-section>
          <q-form
            ref="form"
            class="q-gutter-md"
          >
            <div class="row justify-between">
              <div class="col-5">
                <q-input
                  v-model="payload.firstName"
                  type="text"
                  label="First Name"
                  dense
                  lazy-rules
                  :rules="rules.firstName"
                />
              </div>
              <div class="col-5">
                <q-input
                  v-model="payload.lastName"
                  type="text"
                  label="Last Name"
                  dense
                />
              </div>
            </div>
            <div class="row justify-between">
              <div class="col-12">
                <q-input
                  v-model="payload.username"
                  type="text"
                  label="Username"
                  dense
                  lazy-rules
                />
              </div>
            </div>
            <div class="row justify-between">
              <div class="col-12">
                <q-input
                  v-model="payload.email"
                  type="text"
                  label="Email"
                  dense
                  autocomplete="off"
                  lazy-rules
                  :rules="rules.email"
                />
              </div>
            </div>
            <div class="row justify-between">
              <div class="col-12">
                <q-input
                  v-model="payload.imageUrl"
                  type="text"
                  label="Image URL"
                  dense
                />
              </div>
            </div>
            <div class="row justify-between">
              <div class="col-12">
                <q-input
                  v-model="payload.address"
                  type="textarea"
                  label="Address"
                  dense
                />
              </div>
            </div>
          </q-form>
        </q-card-section>

        <q-separator />

        <q-card-actions justify="space-around">
          <div class="col text-right">
            <q-btn outline size="sm" color="primary" @click="submit">Update</q-btn>
          </div>
        </q-card-actions>
      </q-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 q-mr-lg q-mb-lg">
      <q-editor
        v-model="payload.bio"
        min-height="16rem"
        :definitions="{
          save: {
            tip: 'Save',
            icon: 'save',
            label: 'Save',
            handler: submit
          }
        }"
        :toolbar="[
          ['left','center','right','justify'],
          ['bold','italic','underline','strike'],
          ['undo','redo'],
          ['save']
        ]"
      />
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 q-mr-lg q-mb-lg">
      <q-card>
        <q-card-section>
          <q-form
            ref="formChangePassword"
            class="q-gutter-md"
          >
            <q-input
              v-model="payloadPassword.old"
              :type="showPassword.old ? 'text': 'password'"
              label="Old Password"
              dense
              autocomplete="new-password"
              lazy-rules
              :rules="[(val) => $rules('Old Password', val).required]"
            >
              <template v-slot:append>
                <q-icon class="cursor-pointer" :name="showPassword.old ? 'visibility_off': 'visibility'" @click="showPassword.old = !showPassword.old" />
              </template>
            </q-input>
            <q-input
              v-model="payloadPassword.new"
              :type="showPassword.new ? 'text': 'password'"
              label="New Password"
              dense
              autocomplete="new-password"
              lazy-rules
              :rules="[(val) => $rules('New Password', val).required]"
            >
              <template v-slot:append>
                <q-icon class="cursor-pointer" :name="showPassword.new ? 'visibility_off': 'visibility'" @click="showPassword.new = !showPassword.new" />
              </template>
            </q-input>
            <q-input
              v-model="payloadPassword.newConfirm"
              :type="showPassword.newConfirm ? 'text': 'password'"
              label="Confirmation Password"
              dense
              autocomplete="new-password"
              lazy-rules
              :rules="[((val) => $rules('Confirmation Password', val).required), rules.passwordConfirm[0]]"
            >
              <template v-slot:append>
                <q-icon class="cursor-pointer" :name="showPassword.newConfirm ? 'visibility_off': 'visibility'" @click="showPassword.newConfirm = !showPassword.newConfirm" />
              </template>
            </q-input>
          </q-form>
        </q-card-section>
        <q-separator />
        <q-card-actions justify="space-around">
          <div class="col text-right">
            <q-btn outline size="sm" color="primary" @click="changePassword">Change Password</q-btn>
          </div>
        </q-card-actions>
      </q-card>

      <!-- card erase me -->
      <q-card class="cardEraseMe q-mt-lg">
        <q-card-section>
          <q-checkbox
            v-model="eraseMe"
            :label="eraseMe ? 'Type your password for confirmation!' : 'Delete my account?'"
            dense
            size="sm"
          />
          <q-input
            v-if="eraseMe"
            v-model="eraseMeConfirmation"
            type="password"
            dense
            label="Confirmation"
          />
          <q-btn
            v-if="eraseMe"
            color="red"
            size="sm"
            class="q-mt-sm full-width"
            stack
            @click="onErase"
          >
            Yes, Delete
          </q-btn>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref, watchEffect } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { capitalize } from '../utils/functions'

export default {
  setup (props, ctx) {
    const instance = getCurrentInstance()
    const self = instance.appContext.config.globalProperties
    const store = useStore()

    const dialog = ref({
      show: false,
      message: ''
    })

    const payload = ref({
      address: '',
      bio: '',
      email: '',
      firstName: '',
      lastName: '',
      imageUrl: '',
      username: ''
    })

    const payloadPassword = ref({
      old: '',
      new: '',
      newConfirm: ''
    })

    const showPassword = ref({
      old: false,
      new: false,
      newConfirm: false
    })

    const rules = ref({
      email: [(val) => (val && val.length > 0) || 'Email required'],
      firstName: [(val) => (val && val.length > 0) || 'First name required'],
      passwordConfirm: [(val) => (val && val.length > 0 && val === payloadPassword.value.new) || 'Password confirmation not match']
    })

    const needRelog = ref(false)

    const eraseMe = ref(false)
    const eraseMeConfirmation = ref('')

    const profile = computed(() => store.state.profile)

    watchEffect(() => {
      payload.value = profile.value
    })

    const submit = async () => {
      const valid = await instance?.refs.form.validate()

      if (!valid) return

      const { success, data } = await self.$api.profile.update(payload.value)

      if (success) {
        store.dispatch('setProfileFull', data)
        dialog.value.show = true
        dialog.value.message = 'Update profile success'
      }
    }

    const changePassword = async () => {
      const valid = await instance?.refs.formChangePassword.validate()

      if (!valid) return

      const { success, message } = await self.$api.profile.changePassword({
        oldPassword: payloadPassword.value.old,
        newPassword: payloadPassword.value.new
      })

      dialog.value.show = true
      if (success) {
        needRelog.value = true
        dialog.value.message = 'Change password success. Please re-login.'
      } else {
        dialog.value.message = capitalize(message)
      }
    }

    const onErase = async () => {
      const { success, message } = await self.$api.profile.delete({ password: eraseMeConfirmation.value })
      dialog.value.show = true
      if (success) {
        needRelog.value = true
        dialog.value.message = 'Your account has been deleted.'
      } else {
        dialog.value.message = capitalize(message)
      }
    }

    const onModalClose = () => {
      if (needRelog.value) {
        localStorage.removeItem('token')
        location.href = '/'
      }
    }

    return {
      payload,
      submit,
      rules,
      dialog,
      payloadPassword,
      showPassword,
      changePassword,
      onModalClose,
      eraseMe,
      eraseMeConfirmation,
      onErase
    }
  }
}
</script>
